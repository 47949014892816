import { IMenuItem, IMenuResponse, ISubMenu } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useState } from 'react';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import isSearchOpen from '../../store/search';
import { theme, Theme } from '../../store/theme';
import ThemeToggle from '../Header/ThemeToggle';
import Img from '../Img';
import Portal from '../Portal';
import styles from './NavMenu.module.scss';
import SubmenuItem from './SubmenuItem';

type NavMenuProps = {
  onClose: () => void,
  data: IMenuResponse,
};

const NavMenu = ({ onClose, data }: NavMenuProps) => {
  const [activeSubmenu, setActiveSubmenu] = useState<null | ISubMenu>(null);
  const [startSubmenuClosing, setStartSubmenuClosing] = useState<boolean>(false);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const pathname = usePathname();
  const appTheme = useAtomValue<Theme>(theme);
  const setShowSearch = useSetAtom(isSearchOpen);

  const isSmallDevice = (deviceSize <= ScreenSize.TABLET_LANDSCAPE);
  const hideSubmenu = () => {
    setStartSubmenuClosing(true);
    setTimeout(() => {
      setActiveSubmenu(null);
      setStartSubmenuClosing(false);
    }, 510);
  };

  return (
    <Portal className={styles.menu}>
      <aside className={classnames(styles.sidebar, {
        [styles.sidebar_hidden]: activeSubmenu !== null,
        [styles.sidebar_visible]: startSubmenuClosing,
      })}
      >
        {isSmallDevice && !activeSubmenu && (
          <button
            type='button'
            className={styles.searchButton}
            name='Search'
            onClick={() => setShowSearch(true)}
            aria-label='search'
          >
            <svg>
              <use
                xlinkHref='/media/search.svg#searchSVG'
                href='/media/search.svg#searchSVG'
              />
            </svg>
          </button>
        )}
        <nav className={styles.sidebar__menu}>
          {data.content.map(menuItem => (
            menuItem.type === 'menu-item' ? (
              <Link
                key={`${menuItem.id}/${menuItem.slug}`}
                href={(menuItem as IMenuItem).slug === '' ? '/' : `/${(menuItem as IMenuItem).slug}`}
                className={classnames(styles.menuLink, {
                  [styles.menuLink_active]: (pathname === '/' && (menuItem as IMenuItem).slug === '')
                    || ((menuItem as IMenuItem).slug !== '' && pathname.includes(`/${(menuItem as IMenuItem).slug}`)),
                })}
              >
                {menuItem.title}
              </Link>
            )
              : (
                <button
                  key={menuItem.id}
                  type='button'
                  aria-label={`Show ${menuItem.title} submenu`}
                  onClick={() => (activeSubmenu === menuItem ? hideSubmenu()
                    : setActiveSubmenu((menuItem as ISubMenu)))}
                  className={classnames(styles.menuLink, {
                    [styles.menuLink_active]: (!isSmallDevice && activeSubmenu === menuItem)
                      || (menuItem.type === 'submenu' && !!menuItem.content.find(item => item.slug && pathname.includes(item.slug))),
                  })}
                >
                  {menuItem.title}
                  <div className={styles.menuLink__icon}>
                    <svg>
                      <use
                        xlinkHref='/media/angleRight.svg#angleSVG'
                        href='/media/angleRight.svg#angleSVG'
                      />
                    </svg>
                  </div>
                </button>
              )
          ))}
        </nav>
        <footer className={styles.themeMode}>
          Dark
          <ThemeToggle />
          Light
        </footer>
      </aside>
      <div className={classnames(styles.main, {
        [styles.main_visible]: activeSubmenu !== null,
        [styles.main_hidden]: startSubmenuClosing,
      })}
      >
        {!isSmallDevice && (
          <div className={classnames(styles.illustration, {
            [styles.illustration_hidden]: (activeSubmenu !== null && !startSubmenuClosing) && activeSubmenu?.title !== 'Career',
          })}
          >
            <div className={classnames(styles.image, {
              [styles.image_contacts]: pathname.includes('contacts'),
            })}
            >
              <Img
                src={activeSubmenu?.title === 'Career' || pathname.includes('career')
                  ? `/media/menu/Career_${appTheme}.webp`
                  : pathname.includes('blog')
                    ? `/media/menu/Blog_${appTheme}.webp`
                    : pathname.includes('cases')
                      ? `/media/menu/cases_${appTheme}.webp`
                      : pathname.includes('company')
                        ? `/media/menu/Company_${appTheme}.webp`
                        : pathname.includes('contacts')
                          ? `/media/menu/contacts_${appTheme}.webp`
                          : `/media/menu/home_${appTheme}.webp`}
                alt='sub menu image'
              />
            </div>
          </div>
        )}
        <div className={classnames(styles.main__content, {
          [styles.main__content_visible]: activeSubmenu !== null,
          [styles.main__content_hidden]: startSubmenuClosing,
        })}
        >
          {isSmallDevice && (
            <button
              type='button'
              aria-label='Close submenu'
              className={styles.closeSubmenuButton}
              onClick={hideSubmenu}
            >
              back
            </button>
          )}
          {activeSubmenu !== null && (
            <div className={classnames(styles.submenu, {
              [styles.submenu_career]: activeSubmenu?.title === 'Career',
            })}
            >
              {activeSubmenu?.title === 'Career' && deviceSize <= ScreenSize.TABLET_LANDSCAPE
                && (
                  <div className={styles.mobileCareerIllustration}>
                    <Img
                      src={`/media/menu/Career_${appTheme}.webp`}
                      alt='career image'
                    />
                  </div>
                )}
              {isSmallDevice && <p className={classnames(styles.menuLink, styles.menuLink_active)}>{activeSubmenu.title}</p>}
              <div className={styles.submenu__content}>
                <div className={styles.submenu__column}>
                  {activeSubmenu.content?.slice(0, Math.ceil(activeSubmenu.content.length / 2))
                    .map(submenuItem => (
                      <SubmenuItem
                        key={submenuItem.id}
                        submenuItem={submenuItem}
                      />
                    ))}
                </div>
                <div className={styles.submenu__column}>
                  {activeSubmenu.content?.slice(Math.ceil(activeSubmenu.content.length / 2))
                    .map(submenuItem => (
                      <SubmenuItem
                        key={submenuItem.id}
                        submenuItem={submenuItem}
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <button
        type='button'
        className={styles.closeButton}
        onClick={onClose}
      >
        <svg>
          <use
            xlinkHref='/media/menuClose.svg#menuCloseSVG'
            href='/media/menuClose.svg#menuCloseSVG'
          />
        </svg>
      </button>
    </Portal>
  );
};

export default NavMenu;
